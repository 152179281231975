.swiper-wrapper {
  min-height: 200px;
  padding-bottom: 1rem;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets .swiper-pagination-horizontal {
  width: 20%;
}

.swiper-pagination-bullet {
  background-color: #f3f0eb;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #da7717 !important;
}

.swiper-slide {
  height: 350px;
}

@media screen and (max-width: 320px) {
  .swiper-slide {
    height: 250px;
  }
}
